<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户信息管理</el-breadcrumb-item>
      <el-breadcrumb-item>系统用户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索框区域 -->
      <el-row :gutter="20">
        <el-col :span="8"
          ><div>
            <el-input
              placeholder="请输入姓名关键字"
              v-model="queryInfo.query"
              clearable
              @clear="query"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="query"
              ></el-button>
            </el-input></div
        ></el-col>
        <el-col :span="8"
          ><div>
            <el-button type="primary" @click="addDialogVisible = true"
              >添加用户</el-button
            >
          </div></el-col
        >
      </el-row>
      <el-table :data="userList" border stripe>
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="登录名" prop="LoginName"></el-table-column>
        <el-table-column label="姓名" prop="UserName"></el-table-column>
        <el-table-column label="用户角色" prop="RoleName"></el-table-column>
        <el-table-column
          label="用户所属"
          prop="CompanyName"
        ></el-table-column>
        <el-table-column label="状态（启用/停用）" prop="RoleName">
          <!-- 作用域插槽，绑定状态 -->
          <template slot-scope="scope">
            <el-switch v-if="scope.row.LoginName!=='admin'"
              v-model="scope.row.EnableFlag"
              @change="userStateChanged(scope.row)"
            >
            </el-switch>
             <el-switch v-else disabled
              v-model="scope.row.EnableFlag"
              @change="userStateChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column class="button_column" label="操作" width="350px">
          <template slot-scope="scope">
              <el-button
                type="success"
                size="mini"
                @click="showEditDialog(scope.row.id)"
                v-if="scope.row.LoginName!=='admin'"
              >编辑</el-button>
                   <el-button
                type="success"
                size="mini"
                v-else
               disabled
              >编辑</el-button>
              <el-button
                type="danger"
                size="mini"
                @click="deleteConfirm(scope.row.id)"
                v-if="scope.row.LoginName!=='admin'"
              >删除</el-button>
              <el-button
                type="danger"
                size="mini"
               v-else
               disabled
              >删除</el-button>
              <el-button
                type="warning"
                size="mini"
                @click="resetPwdConfirm(scope.row.id)"
                 v-if="scope.row.LoginName!=='admin'"
              >密码重置</el-button>
              <el-button
                type="warning"
                size="mini"
               v-else
               disabled
              >密码重置</el-button>
             <el-button
                type="primary"
                size="mini"
                @click="showAuthDialog(scope.row.id)"
                 v-if="scope.row.RoleName=='企业管理员'"
              >所属分配</el-button>
              <el-button
                type="primary"
                size="mini"
               v-else
               disabled
              >所属分配</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalRecordCount"
      >
      </el-pagination>

      <el-dialog
        title="添加用户"
        :visible.sync="addDialogVisible"
        width="50%"
        :close="addDialogClose"
        :close-on-click-modal="false"
      >
        <el-form
          :model="addDialogForm"
          :rules="addDialogFormRules"
          ref="addDialogFormRef"
          label-width="100px"
          class="dialog_form"
        >
          <el-form-item label="用户角色">
            <el-select
              v-model="addDialogForm.roleId"
              class="form-select"
              clearable
              filterable
              placeholder="请选择用户角色"
            >
              <el-option
                v-for="item in roleList"
                :key="item.id"
                :label="item.RoleName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="登录名称" prop="loginName">
            <el-input v-model="addDialogForm.loginName"></el-input>
          </el-form-item>
          <el-form-item label="用户姓名" prop="userName">
            <el-input v-model="addDialogForm.userName"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="phoneNumber">
            <el-input v-model="addDialogForm.phoneNumber"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogClose">取 消</el-button>
          <el-button type="primary" @click="saveAdd">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog
        title="编辑用户"
        :visible.sync="editDialogVisible"
        width="50%"
        :close="editDialogClose"
        :close-on-click-modal="false"
      >
        <el-form
          :model="editDialogForm"
          :rules="editDialogFormRules"
          ref="editDialogFormRef"
          label-width="100px"
          class="dialog_form"
        >
          <el-form-item label="用户角色">
            <el-select
              v-model="editDialogForm.roleId"
              class="form-select"
              clearable
              filterable
              placeholder="请选择用户角色"
            >
              <el-option
                v-for="item in roleList"
                :key="item.id"
                :label="item.RoleName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="登录名称" prop="loginName">
            <el-input v-model="editDialogForm.loginName"></el-input>
          </el-form-item>
          <el-form-item label="用户姓名" prop="userName">
            <el-input v-model="editDialogForm.userName"></el-input>
          </el-form-item>
           <el-form-item label="手机号码" prop="phoneNumber">
            <el-input v-model="editDialogForm.phoneNumber"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogClose">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </el-dialog>


 <el-dialog
        title="所属分配"
        :visible.sync="authDialogVisible"
        width="50%"
        :close="authDialogClose"
        :close-on-click-modal="false"
      >
        <el-form
          :model="authDialogForm"
          ref="authDialogFormRef"
          label-width="100px"
          class="dialog_form"
        >
        <div style="float:left;"><span style="color:#409eff;">用户姓名：</span>{{authDialogForm.userName}}</div>
 <div style="margin-left:100px;padding-bottom:10px;"><span style="color:#409eff;">用户角色：</span>{{authDialogForm.roleName}}</div>
                <el-row :gutter="20">
        <el-col :span="12"
          ><div>
            <el-input
              placeholder="请输入企业关键字"
              v-model="authDialogForm.MyCustomerName"
              clearable
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getCustomerList"
              ></el-button>
            </el-input></div
        ></el-col>
      </el-row>
      <el-table :data="companyList" border stripe>
        <el-table-column label="#" type="index"></el-table-column>
        
        <el-table-column label="企业名称" prop="CompanyName"></el-table-column>
        <el-table-column label="联系人" prop="ContactPerson"></el-table-column>
        <el-table-column label="联系方式" prop="ContactPhone"></el-table-column>
        <el-table-column class="button_column" label="操作" width="150px">
          <template slot-scope="scope">
              <el-button
                type="success"
                size="mini"
                @click="setMyCustomerNewsId(scope.row.NewsId,scope.row.CompanyName)"
              >选择</el-button>
          </template>
        </el-table-column>
      </el-table>
        </el-form>
        <el-from>
          <span style="color:#409eff;">当前选择的企业为：</span><span>{{authDialogForm.setMyCustomerName}}</span>
        </el-from>
        <span slot="footer" class="dialog-footer">
          <el-button @click="authDialogClose">取 消</el-button>
          <el-button type="primary" @click="saveAuth">确 定</el-button>
        </span>
      </el-dialog>

    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userList: [],
      totalRecordCount: 0,
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 5,
        // token: window.sessionStorage.getItem("token"),
        // loginname: window.sessionStorage.getItem("loginname"),
      },
      addDialogVisible: false,
      editDialogVisible: false,
      authDialogVisible:false,
      addDialogForm: {
        phoneNumber:"",
        loginName: "",
        userName: "",
        roleId: "",
      },
      editDialogForm: {
        phoneNumber:"",
        loginName: "",
        userName: "",
        roleId: "",
        id: 0,
      },
      authDialogForm:{
        roleName:"",
        userName:"",
        CompanyCode:"",
        MyCustomerName:"",
        setMyCustomerName:"",
        id:0
      },
      companyList:[],
      roleList: [],
      addDialogFormRules: {
        loginName: [
          { required: true, message: "请输入登录名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        userName: [
          { required: true, message: "请输入用户姓名", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        phoneNumber: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "长度在11 个字符",
            trigger: "blur",
          },
        ],
      },
      editDialogFormRules: {
        loginName: [
          { required: true, message: "请输入登录名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        userName: [
          { required: true, message: "请输入用户姓名", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
         phoneNumber: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "长度在11 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getUserList();
    this.getUserRoleList();
  },
  methods: {
    /* 因为是返回的promise对象，所以用async及await */
    async getUserList() {
      //this.$http.post("User/GetUserList", this.queryInfo)
      var paramVar = this.$qs.stringify({
        query: this.queryInfo.query,
        pagenum: this.queryInfo.pagenum,
        pagesize: this.queryInfo.pagesize,
      });
      //await this.$http.get("User/GetUserList", { params: this.queryInfo }).then(
        await this.$http.post("User/GetUserList", paramVar).then(
        (res) => {
          if (res.data.Code !== "200")
            return this.$message.error(res.data.Message);
          this.userList = res.data.Obj;
          this.totalRecordCount = res.data.TotalCount;
        },
        (res) => {
          this.$message.error("远程获取数据失败");
        }
      );
    },
    change(e) {
      this.$forceUpdate();
    },
    query() {
      this.getUserList();
    },
    /* 监听 pagesize 改变事件 */
    handleSizeChange(newsize) {
      this.queryInfo.pagesize = newsize;
      this.getUserList();
    },
    /* 监听 当前页 改变事件 */
    handleCurrentChange(newpage) {
      this.queryInfo.pagenum = newpage;
      this.getUserList();
    },
    /* 监听switch用户状态改变事件 */
    async userStateChanged(userinfo) {
      await this.$http
        .post("User/UpdateUserState/", {
          id: userinfo.id,
          enableflag: userinfo.EnableFlag,
        })
        //.put('User/UpdateUserState/${userinfo.id}/${userinfo.EnableFlag}')
        .then(
          (res) => {
            if (res.data.Code !== "200") {
              userinfo.EnableFlag = !userinfo.EnableFlag;
              return this.$message.error(res.data.Message);
            }
            this.$message.success("用户状态更新成功");
          },
          (res) => {
            userinfo.EnableFlag = !userinfo.EnableFlag;
            this.$message.error("远程操作失败或网络问题");
          }
        );
    },
    addDialogClose() {
      this.addDialogForm.roleId = "";
      this.addDialogVisible = false;
      this.$refs.addDialogFormRef.resetFields();
    },
    async getUserRoleList() {
      await this.$http.get("UserRole/GetUserRoleListByPage/").then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.roleList = res.data.Obj;
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    saveAdd() {
      if (this.addDialogForm.roleId === "")
        return this.$message.error("请选择用户角色");
      this.$refs.addDialogFormRef.validate((valid) => {
        if (!valid) return this.$message.error("关键信息不能为空");
        this.submitAdd();
      });
    },
    async submitAdd() {
      console.log(this.addDialogForm);
      await this.$http.post("User/AddUser/", this.addDialogForm).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$refs.addDialogFormRef.resetFields();
          this.addDialogVisible = false;
          this.$message.success(res.data.Message);
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    editDialogClose() {
      this.editDialogForm.roleId = "";
      this.editDialogVisible = false;
      this.$refs.editDialogFormRef.resetFields();
    },
    showEditDialog(id) {
      var user = null;
      for (var i = 0; i < this.userList.length; i++) {
        if (this.userList[i].id === id) user = this.userList[i];
      }
      this.editDialogForm.roleId = user.RoleId;
      this.editDialogForm.loginName = user.LoginName;
      this.editDialogForm.userName = user.UserName;
      this.editDialogForm.phoneNumber = user.PhoneNumber;
      this.editDialogForm.id = id;
      this.editDialogVisible = true;
    },
    saveEdit() {
      if (this.editDialogForm.roleId === "")
        return this.$message.error("请选择用户角色");
      this.$refs.editDialogFormRef.validate((valid) => {
        if (!valid) return this.$message.error("关键信息不能为空");
        this.submitEdit();
      });
    },
    async submitEdit() {
      await this.$http.post("User/EditUser/", this.editDialogForm).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$refs.editDialogFormRef.resetFields();
          this.editDialogVisible = false;
          this.$message.success(res.data.Message);
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    deleteConfirm(id) {
      this.$confirm("您确实要删除此记录吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除方法
          this.deleteUser(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    async deleteUser(id) {
      await this.$http.post("User/DeleteUser/", { id: id }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    resetPwdConfirm(id) {
      this.$confirm("您确实要重置此记录的密码吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除方法
          this.resetPwd(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    async resetPwd(id) {
      await this.$http.post("User/ResetPwd/", { id: id }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    showAuthDialog(id){
      var user = null;
      for (var i = 0; i < this.userList.length; i++) {
        if (this.userList[i].id === id) user = this.userList[i];
      }
      this.authDialogForm.roleName = user.RoleName;
      this.authDialogForm.userName = user.UserName;
      this.authDialogForm.id = id;
      this.authDialogVisible = true;
    },
    authDialogClose() {
      this.authDialogVisible = false;
    },
    saveAuth() {
      if (this.authDialogForm.MyCustomerNewsId === "")
        return this.$message.error("请选择所属往来单位");
      this.submitAuth();
    },
    async submitAuth() {
      await this.$http.post("User/AuthUser/", this.authDialogForm).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.authDialogVisible = false;
          this.$message.success(res.data.Message);
          this.getUserList();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
        async getCustomerList() {
          console.log(this.authDialogForm.MyCustomerName);
      var paramVar = this.$qs.stringify({
        MyCustomerName:this.authDialogForm.MyCustomerName
      });
      await this.$http.post("Company/GetCompanyListByPage").then(
        (res) => {
          if (res.data.Code !== "200")
            return this.$message.error(res.data.Message);
          this.companyList = res.data.Obj;
        },
        (res) => {
          this.$message.error("远程获取数据失败");
        }
      );
    },
    setMyCustomerNewsId(newsid,name){
    
      this.authDialogForm.CompanyCode=newsid;
      this.authDialogForm.setMyCustomerName=name;
    }
  },
};
</script>

<style lang="less" scoped>

</style>