<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>企业信息管理</el-breadcrumb-item>
      <el-breadcrumb-item>企业信息列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索框区域 -->
      <el-row :gutter="20">
        <el-col :span="8">
          <div>
            <el-input placeholder="请输入企业名称关键字" v-model="queryInfo.query" clearable @clear="query">
              <el-button slot="append" icon="el-icon-search" @click="query"></el-button>
            </el-input>
          </div>
        </el-col>
        <el-col :span="8">
          <div>
            <el-button type="primary" @click="addDialogVisible = true">添加企业</el-button>
          </div>
        </el-col>
      </el-row>
      <el-table :data="dataList" border stripe>
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="企业名称" prop="CompanyName"></el-table-column>
        <el-table-column label="联系人" prop="ContactPerson"></el-table-column>
        <el-table-column label="联系电话" prop="ContactPhone"></el-table-column>
        <el-table-column label="状态（启用/停用）" prop="RoleName">
          <!-- 作用域插槽，绑定状态 -->
          <template slot-scope="scope">
            <el-switch v-model="scope.row.IsEnabled" @change="objStateChanged(scope.row)">
            </el-switch>

          </template>
        </el-table-column>
        <el-table-column class="button_column" label="操作" width="280px">
          <template slot-scope="scope">
            <el-button type="success" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
            <el-button type="danger" size="mini" @click="deleteConfirm(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totalRecordCount">
      </el-pagination>

      <el-dialog title="添加企业" :visible.sync="addDialogVisible" width="50%" :close="addDialogClose" :close-on-click-modal="false">
        <el-form :model="addDialogForm" :rules="addDialogFormRules" ref="addDialogFormRef" label-width="100px" class="dialog_form">
          <el-form-item label="企业名称" prop="CompanyName">
            <el-input v-model="addDialogForm.CompanyName" maxlength="50"></el-input>
          </el-form-item>
           <el-form-item label="联系人" prop="ContactPerson">
            <el-input v-model="addDialogForm.ContactPerson" maxlength="30"></el-input>
          </el-form-item>
           <el-form-item label="联系电话" prop="ContactPhone">
            <el-input v-model="addDialogForm.ContactPhone" maxlength="11"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogClose">取 消</el-button>
          <el-button type="primary" @click="saveAdd">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="编辑企业" :visible.sync="editDialogVisible" width="50%" :close="editDialogClose" :close-on-click-modal="false">
        <el-form :model="editDialogForm" :rules="editDialogFormRules" ref="editDialogFormRef" label-width="100px" class="dialog_form">
          <el-form-item label="企业名称" prop="CompanyName">
            <el-input v-model="editDialogForm.CompanyName" maxlength="50"></el-input>
          </el-form-item>
           <el-form-item label="联系人" prop="ContactPerson">
            <el-input v-model="editDialogForm.ContactPerson" maxlength="30"></el-input>
          </el-form-item>
           <el-form-item label="联系电话" prop="ContactPhone">
            <el-input v-model="editDialogForm.ContactPhone" maxlength="11"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogClose">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataList: [],
      totalRecordCount: 0,
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 5,
      },
      addDialogVisible: false,
      editDialogVisible: false,
      authDialogVisible: false,
      addDialogForm: {
        CompanyName: "",
        ContactPerson: "",
        ContactPhone: "",
      },
      editDialogForm: {
        CompanyName: "",
        ContactPerson: "",
        ContactPhone: "",
        id: 0,
      },
      addDialogFormRules: {
        CompanyName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        ContactPerson: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
         ContactPhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "长度在11 个字符",
            trigger: "blur",
          },
        ],
      },
      editDialogFormRules: {
        CompanyName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /* 因为是返回的promise对象，所以用async及await */
    async getList() {
      var paramVar = this.$qs.stringify({
        query: this.queryInfo.query,
        pagenum: this.queryInfo.pagenum,
        pagesize: this.queryInfo.pagesize,
      });
      await this.$http
        .post("Company/GetList",paramVar)
        .then(
          (res) => {
            if (res.data.Code !== "200")
              return this.$message.error(res.data.Message);
            this.dataList = res.data.Obj;
            this.totalRecordCount = res.data.TotalCount;
          },
          (res) => {
            this.$message.error("远程获取数据失败");
          }
        );
    },
    change(e) {
      this.$forceUpdate();
    },
    query() {
      this.getList();
    },
    /* 监听 pagesize 改变事件 */
    handleSizeChange(newsize) {
      this.queryInfo.pagesize = newsize;
      this.getList();
    },
    /* 监听 当前页 改变事件 */
    handleCurrentChange(newpage) {
      this.queryInfo.pagenum = newpage;
      this.getList();
    },

    addDialogClose() {
      this.addDialogVisible = false;
      this.$refs.addDialogFormRef.resetFields();
    },
    saveAdd() {
      this.submitAdd();
    },
    async submitAdd() {
      await this.$http.post("Company/AddObj/", this.addDialogForm).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$refs.addDialogFormRef.resetFields();
          this.addDialogVisible = false;
          this.$message.success(res.data.Message);
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    editDialogClose() {
      this.editDialogForm.roleId = "";
      this.editDialogVisible = false;
      this.$refs.editDialogFormRef.resetFields();
    },
    showEditDialog(id) {
      var obj = null;
      for (var i = 0; i < this.dataList.length; i++) {
        if (this.dataList[i].id === id) obj = this.userRoleList[i];
      }
      this.editDialogForm.CompanyName = obj.CompanyName;
      this.editDialogForm.ContactPerson = obj.ContactPerson;
      this.editDialogForm.ContactPhone = obj.ContactPhone;
      this.editDialogForm.id = id;
      this.editDialogVisible = true;
    },
    saveEdit() {
      this.submitEdit();
    },
    async submitEdit() {
      await this.$http.post("Company/EditObj/", this.editDialogForm).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$refs.editDialogFormRef.resetFields();
          this.editDialogVisible = false;
          this.$message.success(res.data.Message);
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    deleteConfirm(id) {
      this.$confirm("您确实要删除此记录吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除方法
          this.deleteObj(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    async deleteObj(id) {
      await this.$http.post("Company/DeleteObj/", { id: id }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    async objStateChanged(row) {
      await this.$http
        .post("Company/ChangeObj/", {
          id: row.id,
          enableflag: row.EnableFlag,
        })
        .then(
          (res) => {
            if (res.data.Code !== "200") {
              row.EnableFlag = !row.EnableFlag;
              return this.$message.error(res.data.Message);
            }
            this.$message.success("企业状态更新成功");
          },
          (res) => {
            userinfo.EnableFlag = !userinfo.EnableFlag;
            this.$message.error("远程操作失败或网络问题");
          }
        );
    },

  },
};
</script>

<style lang="less" scoped>
</style>