<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户信息管理</el-breadcrumb-item>
      <el-breadcrumb-item>企业用户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索框区域 -->
      <el-row :gutter="20">
        <el-col :span="8">
          <div>
            <el-input placeholder="请输入姓名关键字" v-model="queryInfo.query" clearable @clear="query">
              <el-button slot="append" icon="el-icon-search" @click="query"></el-button>
            </el-input>
          </div>
        </el-col>
        <el-col :span="8">
          <div>
            <el-button type="primary" @click="addDialogVisible = true">添加用户</el-button>
          </div>
        </el-col>
      </el-row>
      <el-table :data="dataList" border stripe>
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="所在部门" prop="DepartmentName"></el-table-column>
        <el-table-column label="登录名" prop="LoginName"></el-table-column>
        <el-table-column label="姓名" prop="UserName"></el-table-column>
        <el-table-column label="用户角色" prop="RoleName"></el-table-column>
        <el-table-column label="手机号码" prop="PhoneNumber"></el-table-column>
        <el-table-column label="状态（启用/停用）" prop="EnableFlag">
          <!-- 作用域插槽，绑定状态 -->
          <template slot-scope="scope">
            <el-switch v-model="scope.row.EnableFlag" @change="statusChanged(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column class="button_column" label="操作" width="350px">
          <template slot-scope="scope">
            <el-button type="success" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
            <el-button type="danger" size="mini" @click="deleteConfirm(scope.row.id)">删除</el-button>
            <el-button type="warning" size="mini" @click="resetPwdConfirm(scope.row.id)">密码重置</el-button>

          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totalRecordCount">
      </el-pagination>

      <el-dialog title="添加用户" :visible.sync="addDialogVisible" width="50%" :close="addDialogClose" :close-on-click-modal="false">
        <el-form :model="addDialogForm" :rules="addDialogFormRules" ref="addDialogFormRef" label-width="100px" class="dialog_form">
          <el-form-item label="用户角色">
            <el-select v-model="addDialogForm.RoleId" class="form-select" clearable filterable placeholder="请选择用户角色">
              <el-option v-for="item in roleList" :key="item.id" :label="item.RoleName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="登录名称" prop="LoginName">
            <el-input v-model="addDialogForm.LoginName"></el-input>
          </el-form-item>
         <el-form-item label="所在部门">
            <el-select v-model="addDialogForm.DepartmentNewsId" class="form-select" clearable filterable placeholder="请选择所在部门" @change="departmentChange">
              <el-option v-for="item in departmentList" :key="item.NewsId" :label="item.DepartmentName" :value="item.NewsId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="员工姓名">
            <el-select v-model="addDialogForm.StaffNewsId" class="form-select" clearable filterable placeholder="请选择员工姓名" @change="staffChange">
              <el-option v-for="item in staffList" :key="item.NewsId" :label="item.StaffName" :value="item.NewsId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手机号码" prop="PhoneNumber">
            <el-input v-model="addDialogForm.PhoneNumber" maxlength="11"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogClose">取 消</el-button>
          <el-button type="primary" @click="saveAdd">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="编辑用户" :visible.sync="editDialogVisible" width="50%" :close="editDialogClose" :close-on-click-modal="false">
        <el-form :model="editDialogForm" :rules="editDialogFormRules" ref="editDialogFormRef" label-width="100px" class="dialog_form">
          <el-form-item label="用户角色">
            <el-select v-model="editDialogForm.RoleId" class="form-select" clearable filterable placeholder="请选择用户角色" >
              <el-option v-for="item in roleList" :key="item.id" :label="item.RoleName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="登录名称" prop="LoginName">
            <el-input v-model="editDialogForm.LoginName"></el-input>
          </el-form-item>
           <el-form-item label="所在部门" prop="DepartmentName">
            <el-input v-model="editDialogForm.DepartmentName" disabled></el-input>
          </el-form-item>
          <el-form-item label="用户姓名" prop="UserName">
            <el-input v-model="editDialogForm.UserName" disabled></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="PhoneNumber">
            <el-input v-model="editDialogForm.PhoneNumber" maxlength="11"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogClose">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataList: [],
      totalRecordCount: 0,
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 5,
      },
      addDialogVisible: false,
      editDialogVisible: false,
      addDialogForm: {
        DepartmentNewsId: "",
        StaffNewsId:"",
        LoginName: "",
        PhoneNumber: "",
        RoleId: "",
      },
      editDialogForm: {
        DepartmentNewsId: "",
        StaffNewsId:"",
        LoginName: "",
        PhoneNumber: "",
        RoleId: "",
        UserName:"",
        DepartmentName:"",
        id: 0,
      },
      roleList: [],
      departmentList:[],
      staffList:[],
      addDialogFormRules: {
        LoginName: [
          { required: true, message: "请输入登录名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        PhoneNumber: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "长度在11 个字符",
            trigger: "blur",
          },
        ],
      },
      editDialogFormRules: {
        LoginName: [
          { required: true, message: "请输入登录名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        PhoneNumber: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "长度在11 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getList();
    this.getUserRoleList();
    this.getDepartmentList();
  },
  methods: {
    departmentChange(value){
     console.log(value);
     this.getStaffList();
    },
     staffChange(value){
       console.log(value);
     var user = null;
      for (var i = 0; i < this.staffList.length; i++) {
        if (this.staffList[i].NewsId === value) user = this.staffList[i];
      }
      this.addDialogForm.UserName=user.StaffName;
    },
    /* 因为是返回的promise对象，所以用async及await */
    async getList() {
      //this.$http.post("User/GetUserList", this.queryInfo)
      var paramVar = this.$qs.stringify({
        query: this.queryInfo.query,
        pagenum: this.queryInfo.pagenum,
        pagesize: this.queryInfo.pagesize,
      });
      //await this.$http.get("User/GetUserList", { params: this.queryInfo }).then(
      await this.$http.post("UserSub/GetList", paramVar).then(
        (res) => {
          if (res.data.Code !== "200")
            return this.$message.error(res.data.Message);
          this.dataList = res.data.Obj;
          this.totalRecordCount = res.data.TotalCount;
        },
        (res) => {
          this.$message.error("远程获取数据失败");
        }
      );
    },
    change(e) {
      this.$forceUpdate();
    },
    query() {
      this.getList();
    },
    /* 监听 pagesize 改变事件 */
    handleSizeChange(newsize) {
      this.queryInfo.pagesize = newsize;
      this.getList();
    },
    /* 监听 当前页 改变事件 */
    handleCurrentChange(newpage) {
      this.queryInfo.pagenum = newpage;
      this.getList();
    },
    /* 监听switch用户状态改变事件 */
    async statusChanged(row) {
      await this.$http
        .post("UserSub/ChangeObj/", {
          id: row.id,
          Enableflag: row.EnableFlag,
        })
        .then(
          (res) => {
            if (res.data.Code !== "200") {
              row.EnableFlag = !row.EnableFlag;
              return this.$message.error(res.data.Message);
            }
            this.$message.success("用户状态更新成功");
          },
          (res) => {
            row.EnableFlag = !row.EnableFlag;
            this.$message.error("远程操作失败或网络问题");
          }
        );
    },
    addDialogClose() {
      this.addDialogForm.RoleId = "";
      this.addDialogForm.DepartmentNewsId="";
      this.addDialogForm.StaffNewsId="";
      this.addDialogVisible = false;
      this.$refs.addDialogFormRef.resetFields();
    },
    async getUserRoleList() {
      await this.$http.get("UserRole/GetUserRoleListByPage/").then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.roleList = res.data.Obj;
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
        async getDepartmentList() {
      await this.$http.get("Department/GetListByPage/").then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.departmentList = res.data.Obj;
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    async getStaffList() {
       var paramVar = this.$qs.stringify({
        DepartmentCode: this.addDialogForm.DepartmentNewsId,
      });
      await this.$http.post("Staff/GetListByPage/",paramVar).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.staffList = res.data.Obj;
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    saveAdd() {
      if (this.addDialogForm.RoleId === "")
        return this.$message.error("请选择用户角色");
        if (this.addDialogForm.DepartmentNewsId === "")
        return this.$message.error("请选择所属部门");
         if (this.addDialogForm.StaffNewsId === "")
        return this.$message.error("请选择员工");
      this.$refs.addDialogFormRef.validate((valid) => {
        if (!valid) return this.$message.error("关键信息不能为空");
        this.submitAdd();
      });
    },
    async submitAdd() {
      console.log(this.addDialogForm);
      await this.$http.post("UserSub/AddObj/", this.addDialogForm).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$refs.addDialogFormRef.resetFields();
          this.addDialogVisible = false;
          this.$message.success(res.data.Message);
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    editDialogClose() {
       this.editDialogForm.RoleId = "";
      this.editDialogForm.DepartmentNewsId="";
      this.editDialogForm.StaffNewsId="";
      this.editDialogVisible = false;
      this.$refs.editDialogFormRef.resetFields();
    },
    showEditDialog(id) {
      var user = null;
      for (var i = 0; i < this.dataList.length; i++) {
        if (this.dataList[i].id === id) user = this.dataList[i];
      }
      this.editDialogForm.RoleId = user.RoleId;
      this.editDialogForm.LoginName = user.LoginName;
      this.editDialogForm.UserName = user.UserName;
      this.editDialogForm.PhoneNumber = user.PhoneNumber;
      this.editDialogForm.DepartmentName = user.DepartmentName;
      this.editDialogForm.id = id;
      this.editDialogVisible = true;
    },
    saveEdit() {
      if (this.editDialogForm.RoleId === "")
        return this.$message.error("请选择用户角色");
      this.$refs.editDialogFormRef.validate((valid) => {
        if (!valid) return this.$message.error("关键信息不能为空");
        this.submitEdit();
      });
    },
    async submitEdit() {
      await this.$http.post("UserSub/EditObj/", this.editDialogForm).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$refs.editDialogFormRef.resetFields();
          this.editDialogVisible = false;
          this.$message.success(res.data.Message);
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    deleteConfirm(id) {
      this.$confirm("您确实要删除此记录吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除方法
          this.deleteUser(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    async deleteUser(id) {
      await this.$http.post("UserSub/DeleteObj/", { id: id }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    resetPwdConfirm(id) {
      this.$confirm("您确实要重置此记录的密码吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除方法
          this.resetPwd(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    async resetPwd(id) {
      await this.$http.post("UserSub/ResetPwd/", { id: id }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },

  },
};
</script>

<style lang="less" scoped>
</style>